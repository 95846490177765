<!-- list -->
<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" @submit-search="search" @clear-search="clearSearch">
        <el-col :span="6">
          <el-form-item label="角色名称">
            <el-input v-model="searchForm.roleNameLike" placeholder="请输入要搜索的角色名称" />
          </el-form-item>
        </el-col>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button type="primary" @click="add('add')">
            添加角色
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column fixed="left" label="序号" type="index" />
        </template>
        <template slot="table-columns-after">
          <el-table-column label="设置人员" align="center">
            <template slot-scope="scope">
              <el-link type="primary" class="text-btn" @click="goSet(scope.row.roleName, scope.row.roleCode, scope.row.appKey, scope.row.firm.firmCode)">
                设置人员
              </el-link>
            </template>
          </el-table-column>
          <el-table-column label="角色授权" align="center">
            <template slot-scope="scope">
              <el-link type="primary" class="text-btn" @click="set(scope.row.roleName, scope.row.roleCode, scope.row.appKey)">
                授权设置
              </el-link>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="140">
            <template v-slot="scope">
              <el-link type="primary" class="text-btn" @click="add('edit',scope.row.roleName,scope.row.roleCode,scope.row.roleStatus,scope.row.roleCode)">
                修改
              </el-link>
              <el-link type="danger" class="text-btn" @click="del(scope.row.appKey,scope.row.roleCode)">
                删除
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
    <ics-dialog-inner :visible.sync="dialog.menu" :loading="loadingRole.submit" :title="menuTitle" submit-title="保存" cancel-title="取 消" class="form-dialog" width="40%" @submit="submitForm('addForm')">
      <el-form ref="addForm" v-loading="loadingRole.form" label-position="right" label-width="150px" :model="addForm" class="" :rules="rules" @submit.native.prevent="">
        <div class="form-row single">
          <el-form-item label="角色名称：" prop="roleName" style="margin-top: 18px">
            <el-input v-model="addForm.roleName" />
          </el-form-item>
          <el-form-item label="角色状态：" prop="roleStatus">
            <el-radio-group v-model="addForm.roleStatus">
              <el-radio label="20">
                启用
              </el-radio>
              <el-radio label="10">
                禁用
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
      </el-form>
    </ics-dialog-inner>
  </el-main>
</template>
<script>
import { basePageListMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
export default {
  components: { IcsDialogInner },
  mixins: [basePageListMixin],
  data () {
    return {
      baseSearchForm: {
        roleNameLike: ''
      },
      appKey: 'finance-frame-capital',
      companyInfo: this.$store.state.companyInfo,
      columnOption: [
        { label: '角色名称', prop: 'roleName', formatter: this.utils.isEffective, canSet: false, fixed: 'left', width: 140 },
        { label: '角色编码', prop: 'roleCode', formatter: this.utils.isEffective, width: 140 },
        { label: '所属企业', prop: 'firm.firmName', formatter: this.utils.isEffective, width: 180 },
      ],
      dialog: {
        menu: false
      },
      menuTitle: '',
      addForm: {
        roleStatus: '20',
        roleName: ''
      },
      loadingRole: {
        submit: false,
        form: false
      },
      rules: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    getList () {
      this.loading.list = true
      this.api.system.role.findRolePage(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    // 去人员设置
    goSet (roleName, roleCode, appKey, firmCode) {
      const data = {}
      data.appKey = appKey
      data.roleCode = roleCode
      data.roleName = roleName
      data.firmCode = firmCode
      this.$router.push({ name: 'roleRoleSetting', query: data })
    },
    // 去功能设置
    set (roleName, roleCode, appKey) {
      const data = {}
      data.appKey = appKey
      data.roleName = roleName
      data.roleCode = roleCode
      this.$router.push({ name: 'roleSetFunction', query: data })
    },
    // 新增/修改角色
    add (type, roleName, code, roleStatus, roleCode, roleType, tag) {
      this.dialog.menu = true
      this.addForm = {
        roleStatus: '20',
        roleName: ''
      }
      this.addForm.code = this.newRoleCode
      if (type === 'add') {
        this.readonly = true
        this.menuTitle = '新增角色'
      } else {
        this.menuTitle = '修改角色'
        this.readonly = true
        this.addForm.roleName = roleName
        this.addForm.roleCode = code
        this.addForm.roleStatus = roleStatus
      }
      this.addForm.appKey = this.appKey
    },
    // 新增修改菜单保存
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const formData = this._.cloneDeep(this.addForm)
          if (this.menuTitle === '新增角色') {
            this.loadingRole.submit = true
            this.api.system.role.addRelation(formData).then(result => {
              this.$message.success(result.data.message || '新增成功')
              this.loadingRole.submit = false
              this.dialog.menu = false
              this.getList()
            }).catch(e => {
              this.loadingRole.submit = false
            })
          } else {
            this.loadingRole.submit = true
            this.api.system.role.update(formData).then(result => {
              this.$message.success(result.data.message || '修改成功')
              this.loadingRole.submit = false
              this.dialog.menu = false
              this.getList()
            }).catch(e => {
              this.loadingRole.submit = false
            })
          }
        } else {
          return false
        }
      })
    },
    // 弹层关闭数据清理
    closeDialog () {
      this.addForm = {
        roleStatus: '20',
        roleName: '',
        code: '',
        type: '',
        tag: []
      }
      this.$refs.addForm.resetFields()
    },
    // 删除
    del (appKey, roleCode) {
      this.$confirm('确认删除?', '确认删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.system.role.delete(this.appKey, roleCode).then(result => {
          this.$message.success(result.data.message || '删除成功')
          const data = {}
          data.roleNameLike = this.searchForm.roleNameLike
          data.pageNum = this.searchForm.pageNum
          this.getList()
        })
      }).catch(() => {})
    }
  }
}
</script>
<style></style>
